import React from 'react'


import HowItWorks from './HowItWorks'
import Faqs from './Faqs'
import ContactUs from './ContactUs'
import ContactForm from './ContactForm'


function Home() {
  return (
    <div>
      <img
        style={{
          display: 'flex',
          width: '100%',
        }}
        className='d-none d-md-flex'
        src='/images/uzis-house-wide.png'
        alt='house with lights'
      />
      <img
        style={{
          display: 'flex',
          maxWidth: '100%',
        }}
        className='d-flex d-md-none'
        src='/images/uzis-house.png'
        alt='house with lights'
      />
      <div id='HowItWorks'><HowItWorks /></div>
      <div id='Faqs'><Faqs /></div>
      <div id='GetInTouch'><ContactUs /></div>
      <div id='ContactForm'><ContactForm /></div>
    </div>
  )
}

export default Home
