import React from 'react'

import colors from './colors'

const ContactItem = ({ children }) => (
  <div
    style={{
      paddingBottom: '20px',
    }}
  >
    {children}
  </div>
)
const ContactItemLabel = ({ children }) => (
  <div
    style={{
      fontSize: '24px',
      paddingBottom: '10px',
      color: colors.DARK_SECONDARY,
    }}
    className='font-family-brand'
  >
    {children}
  </div>
)
const ContactItemDetail = ({ children }) => (
  <div>
    {children}
  </div>
)
const Link = (props) => (
  <a
    style={{
      color: colors.WHITE,
    }}
    {...props}
  >
    {props.children}
  </a>
)

function Footer () {
  return (
    <div
      style={{
        paddingTop: '40px',
        paddingBottom: '40px',
        backgroundColor: colors.BLUE,
        color: colors.WHITE,
      }}
      className='text-sm-left text-center'
    >
      <div className='container-xl' >
        <div className='row'>
          <div className='col-sm'>
            <h2
              style={{
                fontSize: '40px',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}
              className='font-family-brand'
            >
              check out our work!
            </h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <ContactItem>
              <ContactItemLabel>facebook</ContactItemLabel>
              <ContactItemDetail><a href='https://www.facebook.com/LightElfsLLC' rel='noopener noreferrer' target='_blank' style={{ color: 'inherit' }}>@LightElfsLLC</a></ContactItemDetail>
            </ContactItem>
            <ContactItem>
              <ContactItemLabel>instagram</ContactItemLabel>
              <ContactItemDetail><a href='https://www.instagram.com/light_elfs/' rel='noopener noreferrer' target='_blank' style={{ color: 'inherit' }}>@light_elfs</a></ContactItemDetail>
            </ContactItem>
          </div>
          <div className='col-md-6 text-center text-md-right'>
            <div>
              <div><Link href="/privacy-policy" target="_blank">privacy policy</Link></div>
              <div><Link href="/terms-of-use" target="_blank">terms of use</Link></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
