import React from 'react'

import colors from './colors'

const Step = ({ children }) => (
  <div
    className='col-md'
  >
    {children}
  </div>
)
const StepNumber = ({ children }) => (
  <div
    style={{
      fontSize: '50px',
      color: colors.GREEN,
    }}
    className='font-family-brand'
  >
    {children}
  </div>
)
const StepLabel = ({ children }) => (
  <div
    style={{
      fontSize: '18px',
      paddingBottom: '10px',
      color: colors.SECONDARY,
    }}
  >
    {children}
  </div>
)



function ContactUs () {
  return (
    <div
      style={{
        paddingTop: '40px',
        paddingBottom: '40px',
      }}
      className='text-md-left text-center'
    >
      <div className='container-xl' >
        <div className='row'>
          <div className='col-md'>
            <h2
              style={{
                color: colors.BLUE,
                fontSize: '40px',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}
              className='font-family-brand'
            >
              get on the schedule today!
            </h2>
            <div style={{ paddingBottom: '10px', color: colors.SECONDARY }}>we begin installing lights the last 2 weeks of october, and once we start, our schedule fills up fast!</div>
          </div>
        </div>
        <div className='row'>
          <Step>
            <StepNumber>chat</StepNumber>
            <StepLabel>send us a chat from your browser</StepLabel>
          </Step>
          <Step>
            <StepNumber>email</StepNumber>
            <StepLabel><a href='mailto:memphis@lightelfs.com' style={{ color: 'inherit' }}>memphis@lightelfs.com</a></StepLabel>
          </Step>
          <Step>
            <StepNumber>text or call</StepNumber>
            <StepLabel><a href='tel:+1-901-244-4324' style={{ color: 'inherit' }}>901-244-4324</a></StepLabel>
          </Step>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
