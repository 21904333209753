const configs = {
  test: {
    FIREBASE_HTTPS_MIDDLEWARE_BASE_URL: 'https://us-central1-light-elfs-test.cloudfunctions.net',
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyAT9sf4ejJlDKYcup0NkHwVDoxSDUO9cqU",
      authDomain: "light-elfs-test.firebaseapp.com",
      databaseURL: "https://light-elfs-test.firebaseio.com",
      projectId: "light-elfs-test",
      storageBucket: "light-elfs-test.appspot.com",
      messagingSenderId: "879983432390",
      appId: "1:879983432390:web:fa1e20d1b2d95e4a3cfdc7",
      measurementId: "G-3EXWELC7HR",
    },
    INTERCOM_APP_ID: "t249q28m",
    PRODUCTION: false,
  },
  local: {
    FIREBASE_HTTPS_MIDDLEWARE_BASE_URL: 'http://localhost:5001/light-elfs-test/us-central1',
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyAT9sf4ejJlDKYcup0NkHwVDoxSDUO9cqU",
      authDomain: "light-elfs-test.firebaseapp.com",
      databaseURL: "https://light-elfs-test.firebaseio.com",
      projectId: "light-elfs-test",
      storageBucket: "light-elfs-test.appspot.com",
      messagingSenderId: "879983432390",
      appId: "1:879983432390:web:fa1e20d1b2d95e4a3cfdc7",
      measurementId: "G-3EXWELC7HR",
    },
    INTERCOM_APP_ID: "t249q28m",
    PRODUCTION: false,
  },
  prod: {
    FIREBASE_HTTPS_MIDDLEWARE_BASE_URL: 'https://us-central1-light-elfs.cloudfunctions.net',
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyDzgKQfkYy3wLT5LW0cTS3X6jJGYedwJEw",
      authDomain: "light-elfs.firebaseapp.com",
      databaseURL: "https://light-elfs.firebaseio.com",
      projectId: "light-elfs",
      storageBucket: "light-elfs.appspot.com",
      messagingSenderId: "279052543018",
      appId: "1:279052543018:web:ed6c51c6f8f52c8d6bcf53",
      measurementId: "G-ELZ1F6RL4R",
    },
    INTERCOM_APP_ID: "n36yjplo",
    PRODUCTION: true,
  },
}
console.log(configs[process.env.REACT_APP_CONFIG])
module.exports = configs[process.env.REACT_APP_CONFIG]
