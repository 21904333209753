import React from 'react'
import styled from 'styled-components'

import colors from '../colors'

const StyledSectionHeader = styled.h2`
  color: ${colors.BLUE};
  font-size: 40px;
  padding-bottom: 10px;
  margin-bottom: 10px;
`

const SectionHeader = ({ children }) => (
  <StyledSectionHeader className='font-family-brand'>
    {children}
  </StyledSectionHeader>
)

export default SectionHeader
