import React from 'react'

import colors from '../colors'

function SimpleModal(props) {
  return (
    <div
      style={{
        display: props.visible ? 'flex' : 'none',
        position: 'fixed',
        zIndex: 99999,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, .5)',
        padding: 20,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={(event) => props.onBackgroundClick(event)}
    >
      <div
        style={{
          maxWidth: '90%',
          width: '400px',
          minHeight: '200px',
          backgroundColor: colors.WHITE,
          borderRadius: '4px',
          ...(props.modalStyle ? props.modalStyle : {})
        }}
        onClick={(event) => event.stopPropagation()}
      >
        {props.children}
      </div>
    </div>
  )
}

export default SimpleModal