import React, { useState, useEffect } from 'react';

import { firebase } from './firebase'

async function signOut() {
  try {
    await firebase.auth().signOut()
  } catch (e) {
    console.error(e.message);
  }
}

const Context = React.createContext(null)

function Provider({ children }) {
  const [user, setUser] = useState(undefined)

  // Handle user user changes
  function onAuthStateChanged(user) {
    if (user === null) {
      setUser(null)
    }
    else if (firebase.auth().currentUser) {
      setUser(firebase.auth().currentUser)
    }
  }

  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged)

    return () => {
      subscriber()
    }
  }, []);

  return (
    <Context.Provider
      value={{
        user,
        signOut,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default {
  Provider,
  Consumer: Context.Consumer,
  HelperComponent: ({
    UndefinedComponent = null,
    NullComponent,
    UserComponent,
  }) => (
    <Context.Consumer>
      {(props) => {
        if (props.user === undefined) {
          return UndefinedComponent ? <UndefinedComponent {...props} /> : null
        }
        if (props.user === null) {
          return NullComponent ? <NullComponent {...props} /> : null
        }
        return UserComponent ? <UserComponent {...props} /> : null
      }}
    </Context.Consumer>
  )
}
