import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

import UserContext from './user-context'

import Header from './Header'
import Footer from './Footer'

import Home from "./Home";
import Account from "./Account";
import QuoteBuilder from "./QuoteBuilder";

const App = () => {
  return (
    <UserContext.Provider>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/account" component={Account} />
          <Route path="/quote-builder" component={QuoteBuilder} />
        </Switch>
        <Footer />
      </Router>
    </UserContext.Provider>
  );
}


export default App
