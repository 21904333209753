import React from 'react'

import colors from './colors'

const Step = ({ children }) => (
  <div
    className='col-sm'
  >
    {children}
  </div>
)
const StepNumber = ({ children }) => (
  <div
    style={{
      fontSize: '50px',
      color: colors.GREEN,
    }}
    className='font-family-brand'
  >
    {children}
  </div>
)
const StepLabel = ({ children }) => (
  <div
    style={{
      fontSize: '24px',
      paddingBottom: '10px',
      color: colors.BLUE,
    }}
    className='font-family-brand'
  >
    {children}
  </div>
)
const StepInstructions = ({ children }) => (
  <div
    style={{
      fontSize: '14px',
      color: colors.SECONDARY,
    }}
  >
    {children}
  </div>
)



function HowItWorks () {
  return (
    <div
      style={{
        paddingTop: '40px',
        paddingBottom: '40px',
      }}
      className='text-sm-left text-center'
    >
      <div className='container-xl' >
        <div className='row'>
          <div className='col-sm'>
            <h2
              style={{
                color: colors.BLUE,
                fontSize: '40px',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}
              className='font-family-brand'
            >
              how it works?
            </h2>
          </div>
        </div>
        <div className='row'>
          <Step>
            <StepNumber>1</StepNumber>
            <StepLabel>we'll send you a quote</StepLabel>
            <StepInstructions>we will price out a design just for your house and send back a picture detailing what everything will cost</StepInstructions>
          </Step>
          <Step>
            <StepNumber>2</StepNumber>
            <StepLabel>our elfs will work their magic</StepLabel>
            <StepInstructions>to have your lights ready for after thanksgiving, it's important to get on the schedule late october or early november</StepInstructions>
          </Step>
          <Step>
            <StepNumber>3</StepNumber>
            <StepLabel>take down in january</StepLabel>
            <StepInstructions>our elfs will come back out in the first couple weeks of january and take down everything. since you aren't purchasing the lights, we take them with us and you don't have to worry about storing anything</StepInstructions>
          </Step>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
