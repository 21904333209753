import React from 'react'
import numeral from 'numeral'
import { jsPDF } from 'jspdf'
import { v4 as uuid } from 'uuid'
import queryString from 'query-string'
import { DateTime } from 'luxon'
import axios from 'axios'


import { base64 as logobase64 } from './logo-base64'
import colors from '../colors'

import { firebase, db } from '../firebase'

import SimpleModal from '../Components/SimpleModal'

import env from '../env'

const QUOTE_COLORS = {
  names: {
    'GREEN': 'rgb(0, 255, 0)',
    'RED': 'rgb(255, 0, 0)',
    'CYAN': 'rgb(0, 255, 255)',
    'BLUE': 'rgb(0, 0, 255)',
    'WHITE': 'rgb(255, 255, 255)',
    'YELLOW': 'rgb(255, 255, 0)',
    'DARK GREEN': 'rgb(106, 168, 79)',
  },
  codes: {
    'rgb(0, 255, 0)': 'GREEN',
    'rgb(255, 0, 0)': 'RED',
    'rgb(0, 255, 255)': 'CYAN',
    'rgb(0, 0, 255)': 'BLUE',
    'rgb(255, 255, 255)': 'WHITE',
    'rgb(255, 255, 0)': 'YELLOW',
    'rgb(106, 168, 79)': 'DARK GREEN',
  },
  array: [
    { code: 'rgb(0, 255, 0)', name: 'GREEN' },
    { code: 'rgb(255, 0, 0)', name: 'RED' },
    { code: 'rgb(0, 255, 255)', name: 'CYAN' },
    { code: 'rgb(0, 0, 255)', name: 'BLUE' },
    { code: 'rgb(255, 255, 255)', name: 'WHITE' },
    { code: 'rgb(255, 255, 0)', name: 'YELLOW' },
    { code: 'rgb(106, 168, 79)', name: 'DARK GREEN' },
  ],
}

const CREPE_MYRTLE_SIZES = [
  { label_modifier: 'Large', body_size_width: 13, body_size_height: 13 },
  { label_modifier: 'Medium', body_size_width: 10, body_size_height: 10 },
  { label_modifier: 'Small', body_size_width: 7, body_size_height: 7 },
]

const PRICE_LIST = {
  sections: [
    { color: QUOTE_COLORS.names.GREEN, label: 'Gutter/Roof Line', unit_price: 8.00, units: 100, note: '' },
    { color: QUOTE_COLORS.names.CYAN, label: 'Ridge Line', unit_price: 16.00, units: 0, note: '' },
    { color: QUOTE_COLORS.names.RED, label: 'Ground Lighting', unit_price: 5.00, units: 0, note: '' },
    { color: QUOTE_COLORS.names.YELLOW, label: 'Additional Lighting', unit_price: 5.00, units: 0, note: '' },
    { color: QUOTE_COLORS.names.WHITE, label: 'Nets', unit_price: 50.00, units: 0, note: '', internal_notes: 'Price per net' },
    { color: null, label: 'Garland', unit_price: 60.00, units: 0, note: '', internal_note: 'per 4.5ft Strand' },
    { color: null, label: 'Wreaths', unit_price: 75.00, units: 0, note: '', internal_note: '2x Christmas Designers Price' },
    { color: null, label: 'Mailbox', unit_price: 40.00, units: 0, note: '' },
    { color: null, label: 'Columns / Railing', unit_price: 40.00, units: 0, note: '' },
    { color: null, label: 'Photocell Timer', unit_price: 30.00, units: 1, note: '' },
    { color: null, label: 'Fixed Materials Cost', unit_price: 40.00, units: 1, note: '' },
  ],
  trees: [
    { color: QUOTE_COLORS.names.WHITE, label: 'Tree Trunk', unit_price: 40.00, width: 4, height: 20, spacing: 4, note: '4ft wide, 20ft high, 4inch spacing', internal_note: 'width (ft), height (ft), spacing (inches)' },
    { color: QUOTE_COLORS.names.WHITE, label: 'Crepe Myrtle Branch Wrap', unit_price: 40.00, width: .25, height: 7 * 7, spacing: 4, note: '7, 7ft x 3inch branches, 4inch spacing', internal_note: 'width (ft), height (ft), spacing (inches)' },
    { color: QUOTE_COLORS.names.WHITE, label: 'Crepe Myrtle Outer Wrap', unit_price: 40.00, width: 10, height: 12, spacing: 12, note: '10x12ft with 1ft (12inch) spacing', internal_note: 'width (ft), height (ft), spacing (inches)' },

  ],
  surcharges: [
    { label: 'Non-Warm-White Bulbs', amount: 0.0, percent_amount: .1, note: '10% if any non-white bulbs are used' },
    { label: 'Out of Service Area', amount: 50.0, percent_amount: 0.0, note: 'outside standard service area' },
  ],
  discounts: [
    { label: 'Previous Customer', amount: 0.0, percent_amount: .10, note: '2020 Customer' },
    { label: 'Photocell Timer', amount: 30.00, percent_amount: 0.0, note: 'Merry Christmas!' },
  ],
}

for (const size of CREPE_MYRTLE_SIZES) {
  // branch wrap
  PRICE_LIST.trees.push({
    color: QUOTE_COLORS.names.WHITE,
    label: `Crepe Myrtle Branch Wrap ${size.label_modifier}`,
    unit_price: 40.00,
    width: .25,
    height: 7 * size.body_size_height,
    spacing: 4,
    note: `7, ${size.body_size_height}ftx3inch branches wrapped with 4inch spacing`,
  })
  // outer wrap
  PRICE_LIST.trees.push({
    color: QUOTE_COLORS.names.WHITE,
    label: `Crepe Myrtle Outer Wrap ${size.label_modifier}`,
    unit_price: 40.00,
    width: size.body_size_width,
    height: size.body_size_height,
    spacing: 8,
    note: `${size.body_size_height}ftx${size.body_size_width}ft, 8inch spacing - outer wrap`,
  })
}

function  filterByLabels(items, labels) {
  return items.filter((item) => labels.indexOf(item.label) > -1).map(spread)
}

function spread(item) {
  return { ...item }
}

const default_quote = {
  id: uuid(),
  date: DateTime.local().toFormat('yyyy-LL-dd'),
  customer_name: 'New Customer',
  note: 'All lights are warm white - colors denote quote item\n\nTrees wrapped up to 20ft',
  files: [],
  is_deleted: false,
  show_prepay_discount: true,
  sections: [
    {
      label: 'Main',
      items: filterByLabels(PRICE_LIST.sections, [
        'Gutter/Roof Line',
        'Ridge Line',
        'Ground Lighting',
        'Additional Lighting',
        'Nets',
      ]),
      note: '',
    },
    {
      label: 'Add Ons',
      items: filterByLabels(PRICE_LIST.sections, [
        'Garland',
        'Wreaths',
        'Mailbox',
        'Columns / Railing',
        'Photocell Timer',
      ]),
      note: '',
    },
    {
      label: 'Service Charges',
      items: filterByLabels(PRICE_LIST.sections, [
        'Fixed Materials Cost',
      ]),
      note: '',
    },
  ],
  trees: [
    {
      label: 'Tree (Trunk)',
      items: filterByLabels(PRICE_LIST.trees, [
        'Tree Trunk',
      ]),
      note: '',
    },
    {
      label: 'Crepe Myrtle Branch Wrap',
      items: filterByLabels(PRICE_LIST.trees, [
        'Crepe Myrtle Branch Wrap',
      ]),
      note: '',
    },
    {
      label: 'Crepe Myrtle Outer Wrap',
      items: filterByLabels(PRICE_LIST.trees, [
        'Crepe Myrtle Outer Wrap',
      ]),
      note: '',
    },
  ],
  surcharges: filterByLabels(PRICE_LIST.surcharges, [
    'Non-Warm-White Bulbs',
  ]),
  discounts: filterByLabels(PRICE_LIST.discounts, [
    'Previous Customer',
    'Photocell Timer',
  ]),
}

const default_section = {
  label: 'New Section',
  items: [],
  note: '',
}
const default_tree = {
  label: 'New Tree',
  items: [],
  note: '',
}
const default_item = {
  label: 'Garland',
  unit_price: 10.00,
  units: 1,
  note: '',
}
const default_tree_item = {
  label: 'Branches',
  unit_price: 40.00,
  width: 0.25,
  height: 32,
  spacing: 4,
  note: '3inch branches totaling 32ft. Strands 4inch apart',
}
const default_surcharge = {
  label: 'Charge',
  amount: 50.00,
  percent_amount: 0.0,
  note: '',
}
const default_discount = {
  label: 'Previous Customer',
  amount: 0.00,
  percent_amount: 0.1,
  note: '',
}

function toCurrency(value) {
  return numeral(value).format('$0,0.00')
}

const line_item_row_headers = [
  'Item',
  'Note',
  'Unit Price',
  'Units',
  'Total',
]
const tree_item_row_headers = [
  'Item',
  'Note',
  'Strand Price (23ft)',
  'Width (ft)',
  'Height (ft)',
  'Strand Spacing (inches)',
  'Total',
]
const surcharges_row_headers = [
  'Item',
  'Note',
  'Fixed Amount ($)',
  'Percent Amount',
  'Total',
]
const discounts_row_headers = [
  'Item',
  'Note',
  'Fixed Amount ($)',
  'Percent Amount',
  'Total',
]

function HeaderRow({ headers }) {
  return (
    <tr>
      {
        headers.map((header) => <td key={header}>{header}</td>)
      }
    </tr>
  )
}

function _parseFloat (value) {
  if (typeof value === 'string' && value.length > 0) {
    return parseFloat(value)
  }
  return 0.0
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

function getSpiralBarLength(width, height, gap) {
  const two_pi = 2 * 3.14
  const R = (width / 2)
  const C = two_pi * R
  const P = gap
  const H = height
  const n = H / P
  const L = (n * Math.sqrt(C^2)) + P^2
  return L
}

function getTreeItemTotal(item, unit_l = 23) {
  const L = getSpiralBarLength(item.width, item.height, item.spacing / 12)
  const strands = Math.ceil(L / unit_l)
  return item.unit_price * strands
}

function hasUnitAndUnitPrice(item) {
  return item.unit_price > 0 && item.units > 0
}
function hasWidthHeightSpacingAndUnitPrice(item) {
  return (
    item.unit_price > 0
    && item.width > 0
    && item.height > 0
    && item.spacing > 0
  )
}

function printContent(ref, files, filename) {
  return new Promise((resolve) => {
    var doc = new jsPDF('p', 'mm', 'a4');
    const width = doc.internal.pageSize.getWidth()
    doc.html(ref, {
      callback: async (doc) => {
        const storage_ref = firebase.storage().ref();
        for (const file of files) {
          const file_ref = storage_ref.child(file.storage_ref.storage_path)
          const download_url = await file_ref.getDownloadURL()
          const base64 = await toDataURL(download_url)
          doc.addPage()
          doc.addImage(base64, 'PNG', 0, 0, width, 0)
        }
        doc.save(filename);
        resolve()
      },
      width,
      windowWidth: ref.clientWidth,
    })
  })
}

function FormSection({children}) {
  return (
    <div
      style={{
        marginTop: '4px',
        marginBottom: '4px',
        padding: '10px',
        borderRadius: '4px',
        backgroundColor: colors.WHITE,
      }}
    >
      {children}
    </div>
  )
}
function QuoteModalButton({onClick, children}) {
  const style = {
    width: '100%',
    backgroundColor: colors.WHITE,
    borderColor: colors.WHITE,
    borderStyle: 'solid',
    borderWidth: '1px',
    padding: '10px',
    textAlign: 'center',
  }
  if (typeof onClick !== 'function') {
    return (
      <div
        style={style}
      >
        {children}
      </div>
    )
  }
  return (
    <button
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

function ColorBox({
  color,
  size = '16px',
}) {
  return (
    <span
      style={{
        display: 'inline-block',
        height: size,
        width: size,
        backgroundColor: color,
        marginRight: '8px',
        borderRadius: 2,
        borderColor: color ? '#BBBBBB' : 'transparent',
        borderWidth: '1px',
        borderStyle: 'solid',
      }}
    ></span>
  )
}

function downloadObjectAsJSON(exportObj, exportName){
  var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
  var downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute("href",     dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

function QuoteBuilder({
  history,
  location,
}) {
  function toggleShowPrepay() {
    setQuote({
      ...quote,
      show_prepay: quote.show_prepay === undefined ? false : !quote.show_prepay,
    })
  }
  function addSurcharge(item = default_surcharge) {
    quote.surcharges.push({ ...item })
    setQuote({
      ...quote,
      surcharges: [...quote.surcharges]
    })
  }
  function addDiscount(item = default_discount) {
    quote.discounts.push({ ...item })
    setQuote({
      ...quote,
      discounts: [...quote.discounts]
    })
  }
  function addSection() {
    quote.sections.push({ ...default_section })
    setQuote({
      ...quote,
      sections: [...quote.sections]
    })
  }
  function addTree() {
    quote.trees.push({ ...default_tree })
    setQuote({
      ...quote,
      trees: [...quote.trees]
    })
  }
  function addTreeLineItem(index, item = default_tree_item) {
    quote.trees[index].items.push({ ...item })
    setQuote({ ...quote, trees: [...quote.trees] })
  }

  function addLineItem(index, item = default_item) {
    quote.sections[index].items.push({ ...item })
    setQuote({ ...quote, sections: [...quote.sections] })
  }

  function removeFile(index) {
    quote.files.splice(index, 1)
    setQuote({ ...quote, files: [...quote.files] })
  }

  async function saveQuoteToFirestore() {
    setSaving(true)
    const doc = await db.collection('quotes').doc(quote.id)
    const save_doc = {
      ...quote,
      updated_date: Date.now(),
      updated_by: firebase.auth().currentUser.email,
    }
    await doc.set(save_doc)
    setSaving(false)
  }

  async function deleteQuoteFromFirestore() {
    const yes = window.confirm('Are you sure?')
    if (yes) {
      setSaving(true)
      const doc = await db.collection('quotes').doc(quote.id)
      const save_doc = {
        ...quote,
        updated_date: Date.now(),
        updated_by: firebase.auth().currentUser.email,
        is_deleted: true,
      }
      await doc.set(save_doc)
      await fetchQuotes()
      setSaving(false)
      loadNewQuote()
      setShowFindQuoteModal(true)
    }
  }

  async function loadQuote(quote) {
    setQuote({
      ...quote,
      show_prepay: quote.show_prepay !== undefined ? quote.show_prepay : true,
    })
    const query_params = queryString.parse(location.search)
    query_params.quote_id = quote.id
    const new_search = queryString.stringify(query_params)
    history.push(`${location.pathname}?${new_search}`)
  }

  async function loadQuoteById(quote_id) {
    const ref = db.collection('quotes').doc(quote_id)
    const doc = await ref.get();
    if (doc.data() === undefined) {
      alert('Quote not found - loading new quote')
      return loadNewQuote()
    }
    loadQuote(doc.data())
  }

  function loadNewQuote() {
    loadQuote({
      ...default_quote,
      id: uuid(),
    })
  }

  async function acceptQuote() {
    const token = await firebase.auth().currentUser.getIdToken()
    axios({
      url: `${env.FIREBASE_HTTPS_MIDDLEWARE_BASE_URL}/httpsMiddleware/quote/accept`,
      method: 'POST',
      data: JSON.stringify({
        quote_id: quote.id,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const STRAND_LENGTH = 23
  const [quotes, setQuotes] = React.useState([])
  const [quote, setQuote] = React.useState(null)
  const [quote_search, setQuoteSearch] = React.useState('')
  const [total_price, setTotalPrice] = React.useState(0.0)
  const [sections_total, setSectionsTotal] = React.useState(0.0)
  const [trees_total, setTreesTotal] = React.useState(0.0)
  const [base_total, setBaseTotal] = React.useState(0.0)
  const [surcharges_total, setSurchargesTotal] = React.useState(0.0)
  const [discounts_total, setDiscountTotal] = React.useState(0.0)
  const [show_find_quote_modal, setShowFindQuoteModal] = React.useState(true)
  const [preview_file, setPreviewFile] = React.useState(null)
  const [printing, setPrinting] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [fetching_quotes, setFetchingQuotes] = React.useState(false)
  const print_ref = React.useRef()

  const fetchQuotes = async () => {
    try {
      setFetchingQuotes(true)
      if (firebase) {
        const ref = db.collection('quotes').where('is_deleted', '==', false);
        const docs = await ref.get();

        const quotes = [];
        docs.forEach((doc) => {
          const data = doc.data();
          quotes.push(data);
        });
        setQuotes(quotes);
      }
    } catch (error) {
      console.log('error', error);
    }
    setFetchingQuotes(false)
  };

  React.useEffect(() => {
    fetchQuotes();
  }, [])

  React.useEffect(() => {
    const { quote_id } = queryString.parse(location.search)
    if (quote_id) {
      loadQuoteById(quote_id)
      setShowFindQuoteModal(false)
    }
  }, [])

  React.useEffect(() => {
    if (quote !== null) {
      let sections_total = 0.0
      // line items
      for (const section of quote.sections) {
        for (const item of section.items) {
          if (hasUnitAndUnitPrice(item)) {
            sections_total += item.units * item.unit_price
          }
        }
      }
      setSectionsTotal(sections_total)
    }
  }, [quote])

  React.useEffect(() => {
    if (quote !== null) {
      let trees_total = 0.0
      // line items
      for (const tree of quote.trees) {
        for (const item of tree.items) {
          if (hasWidthHeightSpacingAndUnitPrice(item)) {
            trees_total += getTreeItemTotal(item, STRAND_LENGTH)
          }
        }
      }
      setTreesTotal(trees_total)
    }
  }, [quote])

  React.useEffect(() => {
    if (quote !== null) {
      setBaseTotal(sections_total + trees_total)
    }
  }, [sections_total, trees_total])

  React.useEffect(() => {
    if (quote !== null) {
      // surcharges
      let surcharges_total = 0.0
      for (const surcharge of quote.surcharges) {
        surcharges_total += surcharge.percent_amount > 0 ? base_total * surcharge.percent_amount : surcharge.amount
      }
      setSurchargesTotal(surcharges_total)
    }
  }, [quote, base_total])

  React.useEffect(() => {
    if (quote !== null) {
        // surcharges
      let discounts_total = 0.0
      for (const discount of quote.discounts) {
        discounts_total += discount.percent_amount > 0 ? base_total * discount.percent_amount : discount.amount
      }
      setDiscountTotal(discounts_total)
    }
  }, [quote, base_total])

  React.useEffect(() => {
    if (quote !== null) {
      setTotalPrice(base_total + surcharges_total - discounts_total)
    }
  }, [base_total, surcharges_total, discounts_total])

  if (!firebase.auth().currentUser) {
    return (<div>Please login to view quote builder</div>)
  }
  if (
    firebase.auth().currentUser
    && !(/@lightelfs\.com$/.test(firebase.auth().currentUser.email))
  ) {
    return (<div>You must login with lightelfs.com email address</div>)
  }

  return (
    <React.Fragment>
      {
        quote === null ? (
          <button
            onClick={() => loadNewQuote()}
          >
            begin new quote
          </button>
        ) : (
          <React.Fragment>
            <div>
              <button
                onClick={async () => {
                  setPrinting(true)
                  await new Promise((resolve) => {
                    setTimeout(() => {
                      printContent(
                        print_ref.current,
                        quote.files,
                        `${quote.customer_name} Quote.pdf`
                      ).then(resolve)
                    }, 500)
                  })
                  setPrinting(false)
                }}
              >
                print
              </button>
              <button
                onClick={async () => {
                  // setPrinting(true)
                  await saveQuoteToFirestore()
                  // setPrinting(false)
                }}
                disabled={saving}
              >
                {saving ? 'saving' : 'save'} quote
              </button>
              <button
                onClick={async () => {
                  setShowFindQuoteModal(true)
                }}
              >
                find quote
              </button>
              <button
                onClick={async () => {
                  loadNewQuote()
                }}
              >
                new quote
              </button>
              <button
                onClick={async () => {
                  acceptQuote()
                }}
              >
                accept quote
              </button>
              <button
                onClick={async () => {
                  deleteQuoteFromFirestore()
                }}
              >
                delete quote
              </button>
            </div>

            <div className='container-fluid' style={{ backgroundColor: '#EFEFEF'}}>
              <div className='row'>
                <div className='col-12 col-xl-8'>
                  <FormSection>
                    <div className='form-group'>
                      <label>Date</label>
                      <div>
                        <input className='form-control' value={quote.date} onChange={(event) => setQuote({ ...quote, date: event.target.value })} />
                      </div>
                    </div>
                    <div className='form-group'>
                      <label>Customer Name</label>
                      <div>
                        <input className='form-control' value={quote.customer_name} onChange={(event) => setQuote({ ...quote, customer_name: event.target.value })} />
                      </div>
                    </div>
                    <div className='form-group'>
                      <label>Note</label>
                      <div>
                        <textarea className='form-control' value={quote.note} onChange={(event) => setQuote({ ...quote, note: event.target.value })} />
                      </div>
                    </div>
                  </FormSection>
                  <FormSection>
                    <div>
                      <label>Images</label>
                      <div>
                        <input
                          type='file'
                          onChange={async (e) => {
                            const fileList = e.target.files

                            for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
                              const file = fileList[i];
                              const base64 = await getBase64(file)
                              const storage_path = `${quote.id}/${file.name}`
                              const storage_ref = firebase.storage().ref();
                              const file_ref = storage_ref.child(storage_path)
                              await file_ref.putString(base64, 'data_url')
                              const url = await file_ref.getDownloadURL()
                              quote.files.push({
                                storage_ref: {
                                  storage_path,
                                  url,
                                },
                              })
                            }
                            setQuote({ ...quote, files: [...quote.files] })
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div style={{ paddingTop: '10px' }}>
                        {
                          quote.files.map((file, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  width: '200px',
                                  display: 'inline-block',
                                }}
                              >
                                <img
                                  style={{ display: 'block', width: '100%' }}
                                  src={file.storage_ref.url}
                                  onClick={() => setPreviewFile(file)}
                                />
                                <button
                                  style={{ display: 'block', width: '100%' }}
                                  onClick={() => removeFile(index)}
                                >
                                  remove <i className="fa fa-times"></i>
                                </button>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </FormSection>
                  <FormSection>
                    <div>
                      <label>Line Items</label>
                    </div>
                    {
                      quote.sections.map((section, index) => {

                        return (
                          <React.Fragment key={index}>
                            <table>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td>
                                    <input
                                      value={section.label}
                                      onChange={(event) => {
                                        quote.sections[index].label = event.target.value
                                        setQuote({ ...quote, sections: [...quote.sections] })
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => {
                                        quote.sections.splice(index, 1)
                                        setQuote({ ...quote, sections: [...quote.sections] })
                                      }}
                                    >
                                      <i className="fa fa-times"></i> section
                                    </button>
                                  </td>
                                </tr>
                                <HeaderRow
                                  headers={[''].concat(line_item_row_headers)}
                                />
                                {
                                  section.items.map((item, item_index) => {
                                    return (
                                      <tr
                                        key={item_index}
                                        style={{
                                          paddingTop: '10px',
                                        }}
                                      >
                                        <td>
                                          <button
                                            onClick={() => {
                                              quote.sections[index].items.splice(item_index, 1)
                                              setQuote({ ...quote, sections: [...quote.sections] })
                                            }}
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                          <button
                                            onClick={() => {
                                              const _to_index = Math.max(0, item_index - 1)
                                              const tmp_item = quote.sections[index].items.splice(item_index, 1)[0]
                                              quote.sections[index].items.splice(_to_index, 0, tmp_item)
                                              setQuote({ ...quote, sections: [...quote.sections] })
                                            }}
                                          >
                                            <i className="fa fa-arrow-up"></i>
                                          </button>
                                          <button
                                            onClick={() => {
                                              const _to_index = Math.min(quote.sections[index].items.length - 1, item_index + 1)
                                              const tmp_item = quote.sections[index].items.splice(item_index, 1)[0]
                                              quote.sections[index].items.splice(_to_index, 0, tmp_item)
                                              setQuote({ ...quote, sections: [...quote.sections] })
                                            }}
                                          >
                                            <i className="fa fa-arrow-down"></i>
                                          </button>
                                          <select
                                            value={item.color || ''}
                                            style={{
                                              backgroundColor: item.color,
                                            }}
                                            onChange={(event) => {
                                              console.log(event.target.value)
                                              quote.sections[index].items[item_index].color = event.target.value
                                              setQuote({ ...quote, sections: [...quote.sections] })
                                            }}
                                          >
                                            <option value=''>Quote Color</option>
                                            {
                                              QUOTE_COLORS.array.map(({ code, name }) => (
                                                <option key={code} value={code}>{name}</option>
                                              ))
                                            }
                                          </select>
                                        </td>
                                        <td>
                                          <input
                                            value={item.label}
                                            onChange={(event) => {
                                              quote.sections[index].items[item_index].label = event.target.value
                                              setQuote({ ...quote, sections: [...quote.sections] })
                                            }}
                                          />
                                        </td>
                                        <td style={{ width: '99%' }}>
                                          <input
                                            style={{ width: '100%' }}
                                            value={item.note}
                                            onChange={(event) => {
                                              quote.sections[index].items[item_index].note = event.target.value
                                              setQuote({ ...quote, sections: [...quote.sections] })
                                            }}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            value={item.unit_price}
                                            onChange={(event) => {
                                              quote.sections[index].items[item_index].unit_price = event.target.value
                                              setQuote({ ...quote, sections: [...quote.sections] })
                                            }}
                                            style={{ width: '70px'}}
                                            type='number'
                                          />
                                        </td>
                                        <td>
                                          <input
                                            value={item.units}
                                            onChange={(event) => {
                                              quote.sections[index].items[item_index].units = event.target.value
                                              setQuote({ ...quote, sections: [...quote.sections] })
                                            }}
                                            style={{ width: '70px'}}
                                            type='number'
                                          />
                                        </td>
                                        <td>
                                          {toCurrency(item.units * item.unit_price)}
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                                <tr>
                                  <td colSpan={1}></td>
                                  <td>
                                    <select
                                      value=''
                                      onChange={(event) => {
                                        addLineItem(index, PRICE_LIST.sections[event.target.value])
                                      }}
                                    >
                                      <option value=''>Add Item</option>
                                      {
                                        PRICE_LIST.sections.map((item, index) => (
                                          <option key={index} value={index}>{item.label}</option>
                                        ))
                                      }
                                    </select>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr><td style={{ height: '20px' }}></td></tr>
                              </tbody>
                            </table>
                            <hr />
                          </React.Fragment>
                        )
                      })
                    }
                    <div>
                      <button onClick={() => addSection()}>add section</button>
                    </div>
                  </FormSection>
                  <FormSection>
                    <table style={{ maxWidth: '100%' }}>
                      {
                        quote.trees.map((tree, index) => {

                          return (
                            <React.Fragment key={index}>
                              <tbody>
                                <tr>
                                  <td>
                                    <button
                                      onClick={() => {
                                        quote.trees.splice(index, 1)
                                        setQuote({ ...quote, trees: [...quote.trees] })
                                      }}
                                    >
                                      <i className="fa fa-times"></i> tree
                                    </button>
                                  </td>
                                  <td>
                                    <input
                                      value={tree.label}
                                      onChange={(event) => {
                                        quote.trees[index].label = event.target.value
                                        setQuote({ ...quote, trees: [...quote.trees] })
                                      }}
                                    />
                                  </td>
                                </tr>
                                <HeaderRow
                                  headers={[''].concat(tree_item_row_headers)}
                                />
                                {
                                  tree.items.map((item, item_index) => {
                                    return (
                                      <tr
                                        key={item_index}
                                        style={{
                                          paddingTop: '10px',
                                        }}
                                      >
                                        <td>
                                          <button
                                            onClick={() => {
                                              quote.trees[index].items.splice(item_index, 1)
                                              setQuote({ ...quote, trees: [...quote.trees] })
                                            }}
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                          <button
                                            onClick={() => {
                                              const _to_index = Math.max(0, item_index - 1)
                                              const tmp_item = quote.trees[index].items.splice(item_index, 1)[0]
                                              quote.trees[index].items.splice(_to_index, 0, tmp_item)
                                              setQuote({ ...quote, trees: [...quote.trees] })
                                            }}
                                          >
                                            <i className="fa fa-arrow-up"></i>
                                          </button>
                                          <button
                                            onClick={() => {
                                              const _to_index = Math.min(quote.trees[index].items.length - 1, item_index + 1)
                                              const tmp_item = quote.trees[index].items.splice(item_index, 1)[0]
                                              quote.trees[index].items.splice(_to_index, 0, tmp_item)
                                              setQuote({ ...quote, trees: [...quote.trees] })
                                            }}
                                          >
                                            <i className="fa fa-arrow-down"></i>
                                          </button>
                                          <select
                                            value={item.color || ''}
                                            style={{
                                              backgroundColor: item.color,
                                            }}
                                            onChange={(event) => {
                                              quote.trees[index].items[item_index].color = event.target.value
                                              setQuote({ ...quote, trees: [...quote.trees] })
                                            }}
                                          >
                                            <option value=''>Quote Color</option>
                                            {
                                              QUOTE_COLORS.array.map(({ code, name }) => (
                                                <option key={code} value={code}>{name}</option>
                                              ))
                                            }
                                          </select>
                                        </td>
                                        <td>
                                          <input
                                            value={item.label}
                                            onChange={(event) => {
                                              quote.trees[index].items[item_index].label = event.target.value
                                              setQuote({ ...quote, trees: [...quote.trees] })
                                            }}
                                          />
                                        </td>
                                        <td style={{ width: '99%' }}>
                                          <input
                                            style={{ width: '100%' }}
                                            value={item.note}
                                            onChange={(event) => {
                                              quote.trees[index].items[item_index].note = event.target.value
                                              setQuote({ ...quote, trees: [...quote.trees] })
                                            }}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            value={item.unit_price}
                                            onChange={(event) => {
                                              quote.trees[index].items[item_index].unit_price = event.target.value
                                              setQuote({ ...quote, trees: [...quote.trees] })
                                            }}
                                            style={{ width: '70px'}}
                                            type='number'
                                          />
                                        </td>
                                        <td>
                                          <input
                                            value={item.width}
                                            onChange={(event) => {
                                              quote.trees[index].items[item_index].width = event.target.value
                                              setQuote({ ...quote, trees: [...quote.trees] })
                                            }}
                                            style={{ width: '70px'}}
                                            type='number'
                                          />
                                        </td>
                                        <td>
                                          <input
                                            value={item.height}
                                            onChange={(event) => {
                                              quote.trees[index].items[item_index].height = event.target.value
                                              setQuote({ ...quote, trees: [...quote.trees] })
                                            }}
                                            style={{ width: '70px'}}
                                            type='number'
                                          />
                                        </td>
                                        <td>
                                          <input
                                            value={item.spacing}
                                            onChange={(event) => {
                                              quote.trees[index].items[item_index].spacing = event.target.value
                                              setQuote({ ...quote, trees: [...quote.trees] })
                                            }}
                                            style={{ width: '70px'}}
                                            type='number'
                                          />
                                        </td>
                                        <td>
                                          {toCurrency(getTreeItemTotal(item, STRAND_LENGTH))}
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                                <tr>
                                  <td colSpan={1}></td>
                                  <td>
                                    <select
                                      value=''
                                      style={{
                                        width: '100%',
                                      }}
                                      onChange={(event) => {
                                        addTreeLineItem(index, PRICE_LIST.trees[event.target.value])
                                      }}
                                    >
                                      <option value=''>Add Item</option>
                                      {
                                        PRICE_LIST.trees.map((item, index) => (
                                          <option key={index} value={index}>{item.label}</option>
                                        ))
                                      }
                                    </select>
                                  </td>
                                </tr>
                              </tbody>
                            </React.Fragment>
                          )
                        })
                      }
                    </table>
                    <div>
                      <button onClick={() => addTree()}>add tree</button>
                    </div>
                  </FormSection>
                  <FormSection>
                    <label>Additional Charges</label>
                    <table>
                      <tbody>
                        <HeaderRow
                          headers={[''].concat(surcharges_row_headers)}
                        />
                        {
                          quote.surcharges.map((surcharge, index) => {
                            return (
                              <tr
                                key={index}
                                style={{
                                  paddingTop: '10px',
                                }}
                              >
                                <td>
                                  <button
                                    onClick={() => {
                                      quote.surcharges.splice(index, 1)
                                      setQuote({ ...quote, surcharges: [...quote.surcharges] })
                                    }}
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>
                                  <button
                                    onClick={() => {
                                      const _to_index = Math.max(0, index - 1)
                                      const tmp_item = quote.surcharges.splice(index, 1)[0]
                                      quote.surcharges.splice(_to_index, 0, tmp_item)
                                      setQuote({ ...quote, surcharges: [...quote.surcharges] })
                                    }}
                                  >
                                    <i className="fa fa-arrow-up"></i>
                                  </button>
                                  <button
                                    onClick={() => {
                                      const _to_index = Math.min(quote.surcharges.length - 1, index + 1)
                                      const tmp_item = quote.surcharges.splice(index, 1)[0]
                                      quote.surcharges.splice(_to_index, 0, tmp_item)
                                      setQuote({ ...quote, surcharges: [...quote.surcharges] })
                                    }}
                                  >
                                    <i className="fa fa-arrow-down"></i>
                                  </button>
                                </td>
                                <td>
                                  <input
                                    value={surcharge.label}
                                    onChange={(event) => {
                                      quote.surcharges[index].label = event.target.value
                                      setQuote({ ...quote, surcharges: [...quote.surcharges] })
                                    }}
                                  />
                                </td>
                                <td style={{ width: '99%' }}>
                                  <input
                                    style={{ width: '100%' }}
                                    value={surcharge.note}
                                    onChange={(event) => {
                                      quote.surcharges[index].note = event.target.value
                                      setQuote({ ...quote, surcharges: [...quote.surcharges] })
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    value={surcharge.amount}
                                    onChange={(event) => {
                                      quote.surcharges[index].amount = _parseFloat(event.target.value)
                                      setQuote({ ...quote, surcharges: [...quote.surcharges] })
                                    }}
                                    style={{ width: '70px'}}
                                    type='number'
                                  />
                                </td>
                                <td>
                                  <input
                                    value={surcharge.percent_amount}
                                    onChange={(event) => {
                                      quote.surcharges[index].percent_amount = _parseFloat(event.target.value)
                                      setQuote({ ...quote, surcharges: [...quote.surcharges] })
                                    }}
                                    tooltip='Percent'
                                    step='0.05'
                                    style={{ width: '70px'}}
                                    type='number'
                                  />
                                </td>
                                <td>
                                  {toCurrency(surcharge.percent_amount ? base_total * surcharge.percent_amount : surcharge.amount)}
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    <div>
                      <select
                        value=''
                        onChange={(event) => {
                          addSurcharge(PRICE_LIST.surcharges[event.target.value])
                        }}
                      >
                        <option value=''>Add Additional Charge Item</option>
                        {
                          PRICE_LIST.surcharges.map((item, index) => (
                            <option key={index} value={index}>{item.label}</option>
                          ))
                        }
                      </select>
                    </div>
                  </FormSection>
                  <FormSection>
                    <label>Discounts</label>
                    <table>
                      <tbody>
                        <HeaderRow
                          headers={[''].concat(discounts_row_headers)}
                        />
                        {
                          quote.discounts.map((discount, index) => {
                            return (
                              <tr
                                key={index}
                                style={{
                                  paddingTop: '10px',
                                }}
                              >
                                <td>
                                  <button
                                    onClick={() => {
                                      quote.discounts.splice(index, 1)
                                      setQuote({ ...quote, discounts: [...quote.discounts] })
                                    }}
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>
                                  <button
                                    onClick={() => {
                                      const _to_index = Math.max(0, index - 1)
                                      const tmp_item = quote.discounts.splice(index, 1)[0]
                                      quote.discounts.splice(_to_index, 0, tmp_item)
                                      setQuote({ ...quote, discounts: [...quote.discounts] })
                                    }}
                                  >
                                    <i className="fa fa-arrow-up"></i>
                                  </button>
                                  <button
                                    onClick={() => {
                                      const _to_index = Math.min(quote.discounts.length - 1, index + 1)
                                      const tmp_item = quote.discounts.splice(index, 1)[0]
                                      quote.discounts.splice(_to_index, 0, tmp_item)
                                      setQuote({ ...quote, discounts: [...quote.discounts] })
                                    }}
                                  >
                                    <i className="fa fa-arrow-down"></i>
                                  </button>
                                </td>
                                <td>
                                  <input
                                    value={discount.label}
                                    onChange={(event) => {
                                      quote.discounts[index].label = event.target.value
                                      setQuote({ ...quote, discounts: [...quote.discounts] })
                                    }}
                                  />
                                </td>
                                <td style={{ width: '99%' }}>
                                  <input
                                    style={{ width: '100%' }}
                                    value={discount.note}
                                    onChange={(event) => {
                                      quote.discounts[index].note = event.target.value
                                      setQuote({ ...quote, discounts: [...quote.discounts] })
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    value={discount.amount}
                                    onChange={(event) => {
                                      quote.discounts[index].amount = _parseFloat(event.target.value)
                                      setQuote({ ...quote, discounts: [...quote.discounts] })
                                    }}
                                    style={{ width: '70px'}}
                                    type='number'
                                  />
                                </td>
                                <td>
                                  <input
                                    value={discount.percent_amount}
                                    onChange={(event) => {
                                      quote.discounts[index].percent_amount = _parseFloat(event.target.value)
                                      setQuote({ ...quote, discounts: [...quote.discounts] })
                                    }}
                                    tooltip='Percent'
                                    step='0.05'
                                    style={{ width: '70px'}}
                                    type='number'
                                  />
                                </td>
                                <td>
                                  {toCurrency(discount.percent_amount ? base_total * discount.percent_amount : discount.amount)}
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    <div>
                      <select
                        value=''
                        onChange={(event) => {
                          addDiscount(PRICE_LIST.discounts[event.target.value])
                        }}
                      >
                        <option value=''>Add Discount</option>
                        {
                          PRICE_LIST.discounts.map((item, index) => (
                            <option key={index} value={index}>{item.label}</option>
                          ))
                        }
                      </select>
                    </div>
                  </FormSection>
                  <table>
                    <tbody>
                      <tr>
                        <td colSpan={line_item_row_headers.length - 1}></td>
                        <td>Total (without tax)</td>
                        <td>{toCurrency(total_price)}</td>
                      </tr>
                      <tr>
                        <td colSpan={line_item_row_headers.length - 1}>
                          <button onClick={() => toggleShowPrepay()}>
                            toggle prepay
                          </button>
                        </td>
                        <td>Prepay Total (without tax)</td>
                        <td>{toCurrency(total_price - (total_price * .05))}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/*

                  QUOTE DISPLAY

                */}
                <div
                  className={printing ? '' : 'col-12 col-xl-4'}
                  ref={print_ref}
                  style={
                    printing ? ({
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: 824.5,
                      display: 'block',
                    }) : ({
                      display: 'block',
                    })
                  }
                >
                  <div style={{ backgroundColor: colors.BLUE, color: colors.WHITE, padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ height: '50px' }}>
                        light <img src={logobase64} style={{height: '100%' }} /> elfs
                      </div>
                      <div>
                        <h3 className='m-0'>{quote.customer_name}</h3>
                        <h6 className='m-0'>{quote.date}</h6>
                      </div>
                    </div>
                  </div>
                  <div>
                    {
                      quote.note.length > 0 ? (
                        <div className='alert alert-light m-1' style={{ whiteSpace: 'pre-wrap' }}>
                          {quote.note}
                        </div>
                      ) : null
                    }
                    <div>
                      <table className='table'>
                        <tbody>
                          <tr>
                            <td className='bg-light font-weight-bold' colSpan={line_item_row_headers.length - 2} style={{ textAlign: 'center' }}>Line Items</td>
                          </tr>
                          {
                            quote.sections.map((section, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td className='text-secondary'>{section.label}</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                  {
                                    section.items.filter(hasUnitAndUnitPrice).map((item, item_index) => {

                                      return (
                                        <tr key={item_index}>
                                          <td>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                              <ColorBox color={item.color}/>{item.label}
                                            </div>
                                          </td>
                                          <td>{item.note}</td>
                                          <td>{toCurrency(item.unit_price * item.units)}</td>
                                        </tr>
                                      )
                                    })
                                  }
                                </React.Fragment>
                              )
                            })
                          }
                        </tbody>
                        {
                          quote.trees.length > 0 ? (
                            <tbody>
                              <tr>
                                <td className='bg-light font-weight-bold' colSpan={line_item_row_headers.length - 2} style={{ textAlign: 'center' }}>Trees</td>
                              </tr>
                              {
                                quote.trees.map((tree, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td className='text-secondary'>{tree.label}</td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                      {
                                        tree.items.filter(hasWidthHeightSpacingAndUnitPrice).map((item, item_index) => {

                                          return (
                                            <React.Fragment key={item_index}>
                                              <tr>
                                                <td><ColorBox color={item.color}/>{item.label}</td>
                                                <td>{item.note}</td>
                                                <td>{toCurrency(getTreeItemTotal(item, STRAND_LENGTH))}</td>
                                              </tr>
                                            </React.Fragment>
                                          )
                                        })
                                      }
                                    </React.Fragment>
                                  )
                                })
                              }
                            </tbody>
                          ) : null
                        }
                        {
                          quote.surcharges.length > 0 ? (
                            <tbody>
                              <tr>
                                <td className='bg-light font-weight-bold' colSpan={line_item_row_headers.length - 2} style={{ textAlign: 'center' }}>Additional Charges</td>
                              </tr>
                              {
                                quote.surcharges.map((surcharge, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{surcharge.label}</td>
                                      <td>{surcharge.note}</td>
                                      <td>{toCurrency(surcharge.percent_amount > 0 ? base_total * surcharge.percent_amount : surcharge.amount)}</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          ) : null
                        }
                        {
                          quote.discounts.length > 0 ? (
                            <tbody>
                              <tr>
                                <td className='bg-light font-weight-bold' colSpan={line_item_row_headers.length - 2} style={{ textAlign: 'center' }}>Discounts</td>
                              </tr>
                              {
                                quote.discounts.map((discount, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{discount.label}</td>
                                      <td>{discount.note}</td>
                                      <td>-{toCurrency(discount.percent_amount > 0 ? base_total * discount.percent_amount : discount.amount)}</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          ) : null
                        }
                        <tbody>
                          <tr>
                            <td colSpan={line_item_row_headers.length - 4}></td>
                            <td>Total (without tax)</td>
                            <td>{toCurrency(total_price)}</td>
                          </tr>
                          {
                            quote.show_prepay === true || quote.show_prepay === undefined ? (
                              <tr>
                                <td colSpan={line_item_row_headers.length - 4}></td>
                                <td>Prepay Total (without tax)</td>
                                <td>{toCurrency(total_price - (total_price * .05))}</td>
                              </tr>
                            ) : null
                          }
                        </tbody>
                      </table>
                      <div style={{ color: colors.DARK_GRAY, padding: '10px' }}>
                        <div>Additional Service Agreements</div>
                        {
                          quote.show_prepay === true || quote.show_prepay === undefined ? (
                            <div>
                              *To receive Prepay price, invoice must be paid within 5 days of receiving quote and not on or after the date of installation
                            </div>
                          ) : null
                        }
                        <div>
                          *light elfs must have access to all areas of the property and home to complete the job as well as access to at least one 120v outlet that has power. If a reschedule is forced due to limited access to property or no power source, an additional $100 service charge may be applied to your account
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }

      <SimpleModal
        visible={preview_file !== null}
        onBackgroundClick={() => setPreviewFile(null)}
        modalStyle={{
          width: '800px',
        }}
      >
        {
          preview_file ? (
            <React.Fragment>
              <img
                style={{ display: 'block', width: '100%' }}
                src={preview_file.storage_ref.url}
                onClick={() => setPreviewFile(preview_file)}
              />
              <button
                style={{ display: 'block', width: '100%' }}
                onClick={() => setPreviewFile(null)}
              >
                close <i className="fa fa-times"></i>
              </button>
            </React.Fragment>
          ) : null
        }
      </SimpleModal>

      <SimpleModal
        visible={show_find_quote_modal}
        onBackgroundClick={() => setShowFindQuoteModal(false)}
        modalStyle={{}}
      >
        <QuoteModalButton>
          Existing Quotes
        </QuoteModalButton>
        <div style={{ backgroundColor: colors.OFFWHITE, height: '4px' }}></div>
        <div
          style={{
            maxHeight: '300px',
            overflowY: 'scroll',
          }}
        >
          <QuoteModalButton>
            <input
              className='form-control'
              value={quote_search}
              placeholder='search by name'
              onChange={(event) => setQuoteSearch(event.target.value)}
            />
          </QuoteModalButton>
          {
            [].concat(quotes)
              .filter((quote) => {
                if (quote_search.length > 0) {
                  const regex = new RegExp(quote_search, 'ig')
                  return regex.test(quote.customer_name)
                }
                return true
              })
              .sort((a, b) => a.customer_name.localeCompare(b.customer_name) ? -1 : 1).map((quote) => {
              return (
                <QuoteModalButton
                  key={quote.id}
                  onClick={() => loadQuote(quote)}
                >
                  {quote.customer_name} - {quote.date}
                </QuoteModalButton>
              )
            })
          }
        </div>
        <div style={{ backgroundColor: colors.OFFWHITE, height: '4px' }}></div>
        <QuoteModalButton
          onClick={() => {
            loadNewQuote()
            setShowFindQuoteModal(false)
          }}
        >
          begin new quote
        </QuoteModalButton>
        <QuoteModalButton
          onClick={(event) => {
            event.stopPropagation()
            fetchQuotes()
          }}
        >
          refresh quote list
        </QuoteModalButton>
        <QuoteModalButton
          onClick={() => {
            setShowFindQuoteModal(false)
          }}
        >
          cancel
        </QuoteModalButton>
      </SimpleModal>
    </React.Fragment>
  )
}

export default QuoteBuilder