import React, { useState } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { firebase } from './firebase'

import env from './env'
import colors from './colors'
import SectionHeader from './Components/SectionHeader'

import UserContext from './user-context'

function Login () {
  const makeRequest = async (event) => {
    event.preventDefault()
    setOpeningPayment(true)
    const token = await firebase.auth().currentUser.getIdToken()
    fetch(
      `${env.FIREBASE_HTTPS_MIDDLEWARE_BASE_URL}/httpsMiddleware/getStripeSession`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (response) => {
      const data = await response.json()
      window.open(data.url)
    }).catch((error) => {
      alert(error.message)
    }).then(() => {
      setOpeningPayment(false)
    })
  }

  const [opening_payment, setOpeningPayment] = useState(false)

  return (
    <div>
      <UserContext.HelperComponent
        NullComponent={() => (
          <div
            style={{
              padding: '40px',
              paddingTop: '60px',
              paddingBottom: '60px',
              backgroundColor: colors.OFFWHITE,
              textAlign: 'center',
            }}
          >
            <div
              style={{
                padding: '20px',
                backgroundColor: colors.WHITE,
                textAlign: 'center',
                display: 'inline-block',
                border: `1px solid ${colors.DARK_SECONDARY}`,
              }}
            >
              <h3
                style={{
                  color: colors.SECONDARY,
                  paddingBottom: '20px',
                }}
                className='font-family-brand'
              >
                sign in
              </h3>
              <StyledFirebaseAuth
                uiConfig={{
                  callbacks: {
                    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                      return false;
                    }
                  },
                  signInOptions: [
                    firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                  ],
                }}
                firebaseAuth={firebase.auth()}
              />
            </div>
          </div>
        )}
        UserComponent={(props) => (
          <div>
            <div
              className='container-xl'
              style={{
                paddingTop: '40px',
                paddingBottom: '180px',
              }}
            >
              <div className='row'>
                <div className='col-12'>
                  <SectionHeader>your account info</SectionHeader>
                  <div style={{ paddingBottom: '20px' }}>
                    <span style={{ fontWeight: 800 }}>email:&nbsp;</span><span>{props.user.email}</span>
                  </div>
                  <div style={{ paddingBottom: '20px' }}>
                    <button
                      className='btn btn-info'
                      onClick={(event) => makeRequest(event)}
                      disabled={opening_payment}
                    >
                      manage payment information
                    </button>
                  </div>
                  <div style={{ paddingBottom: '20px' }}>
                    <button onClick={props.signOut} className='btn btn-danger'>log out</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  )
}


export default Login
