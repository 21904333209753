import React from 'react'

import colors from './colors'

const Faq = ({ children }) => (
  <div
    style={{
      paddingBottom: '20px',
    }}
  >
    {children}
  </div>
)
const FaqQuestion = ({ children }) => (
  <div
    style={{
      fontSize: '24px',
      paddingBottom: '10px',
      color: colors.DARK_SECONDARY,
    }}
    className='font-family-brand'
  >
    {children}
  </div>
)
const FaqAnswer = ({ children }) => (
  <div>
    {children}
  </div>
)

function Faqs () {
  return (
    <div
      style={{
        paddingTop: '40px',
        paddingBottom: '40px',
        backgroundColor: colors.BLUE,
        color: colors.WHITE,
      }}
      className='text-sm-left text-center'
    >
      <div className='container-xl' >
        <div className='row'>
          <div className='col-sm'>
            <h2
              style={{
                fontSize: '40px',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}
              className='font-family-brand'
            >
             faqs
            </h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm'>
            <Faq>
              <FaqQuestion>when do you put lights up?</FaqQuestion>
              <FaqAnswer>we begin putting lights up the last 2 weeks of october. our schedule will fill up quickly, so it's important to get on our schedule to have your lights ready to go after Thanksgiving</FaqAnswer>
            </Faq>
            <Faq>
              <FaqQuestion>do you provide the lights?</FaqQuestion>
              <FaqAnswer>yes, we will bring all the lights needed. you don't need to store anything, and since the lights are ours, we guarantee them all season</FaqAnswer>
            </Faq>
            <Faq>
              <FaqQuestion>is takedown included?</FaqQuestion>
              <FaqAnswer>yes, the quote you receive back from us includes takedown in january</FaqAnswer>
            </Faq>
            <Faq>
              <FaqQuestion>how much is a normal house?</FaqQuestion>
              <FaqAnswer>to do the gutter line on a traditional 3bed/2bathroom is around $900</FaqAnswer>
            </Faq>
            <Faq>
              <FaqQuestion>what color lights are available?</FaqQuestion>
              <FaqAnswer>warm white (most popular), cool white, pure white, blue, yellow, red, green, amber/orange, multicolor, RGB, purple, pink, teal</FaqAnswer>
            </Faq>
          </div>
        </div>
      </div>
    </div>
  )
}
// <a style={{color: 'inherit', textDecoration: 'underline'}} href='https://www.christmasdesigners.com/christmas-lights/led-christmas-lights/c9-christmas-lights/c9-led-retrofit-bulb.html' rel='noopener noreferrer' target='_blank'>Christmas Designers</a>
export default Faqs
