const colors = {
  RED: '#da1818',
  BLUE: '#0194FE',
  GREEN: '#53D787',
  WHITE: '#FFFFFF',
  OFFWHITE: '#EFEFEF',
  SECONDARY: '#b0b0b0',
  DARK_SECONDARY: '#d0d0d0',
  DARK_GRAY: '#545454',
}


export default colors
