import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import env from './env'
import colors from './colors'
import UserContext from './user-context'
import { firebase } from './firebase'

const Input = styled.input`
  border-radius: 4px;
  border: 2px solid ${colors.OFFWHITE};
  padding: 10px;
  background-color: ${colors.OFFWHITE};
  width: 100%;
  color: ${colors.DARK_GRAY};
  &:focus, &:active {
    color: ${colors.BLUE};
    outline: none;
    background-color: ${colors.WHITE};
    border: 2px solid ${colors.GREEN};
    box-shadow: none;
  }
`
const Textarea = styled(Input).attrs({
  as: 'textarea',
})``
const Select = styled(Input).attrs({
  as: 'select',
})``

const FormButton = styled.button`
  background-color: ${colors.GREEN};
  color: ${colors.WHITE};
  border-radius: 4px;
  border: none;
  padding: 10px;
  transition: box-shadow 200ms;
  &:hover {
    box-shadow: 0 0 10px ${colors.SECONDARY};
  }
  &:focus, &:active {
    outline: none;
  }
  &:disabled {
    background-color: ${colors.BLUE};
  }
`

const source_options = [
  'Door Hanger',
  'Vehicle',
  'Referral',
  'Instagram',
  'Facebook',
  'Nextdoor',
  'Email',
  'Google',
  'Other',
]

function WebsiteForm (props) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [source, setSource] = useState('')
  const [more_info, setMoreInfo] = useState('')

  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [error_message, setErrorMessage] = useState('')

  // const [name, setName] = useState('steven kaspar')
  // const [email, setEmail] = useState('srkaspar+test@gmail.com')
  // const [phone, setPhone] = useState('901-652-7046')
  // const [address, setAddress] = useState('4880 Normandy Ln, Memphis, TN 38117')
  // const [source, setSource] = useState('Instagram')
  // const [more_info, setMoreInfo] = useState('gutter line with pure white lights and one column')

  useEffect(() => {
    if (props.user && props.user.email) {
      setName(props.user.displayName)
      setEmail(props.user.email)
    }
  }, [props])

  function handleSubmit(event) {
    firebase.analytics().logEvent('contact_form_submitted')
    event.preventDefault()
    const form_data = {
      name,
      email,
      phone,
      address,
      source,
      more_info,
    }
    setSubmitting(true)
    axios({
      url: `${env.FIREBASE_HTTPS_MIDDLEWARE_BASE_URL}/httpsMiddleware/website_form`,
      method: 'POST',
      data: JSON.stringify(form_data),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    }).then(() => {
      setErrorMessage('')
      setSubmitted(true)
    }).catch((error) => {
      setErrorMessage(error.message)
      setSubmitted(false)
    }).then(() => {
      setSubmitting(false)
    })
  }
  return (
    <form className='text-left' onSubmit={handleSubmit}>
      {
        props.user === null || props.user === undefined ? (
          <Fragment>
            <div className='form-group row'>
              <label htmlFor='' className='col-sm-3 col-form-label'>full name</label>
              <div className='col-sm-9'>
                <Input required placeholder='santa claus' type='text' value={name} onChange={(event) => setName(event.target.value)} />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='' className='col-sm-3 col-form-label'>email</label>
              <div className='col-sm-9'>
                <Input required placeholder='your@email.com' type='text' value={email} onChange={(event) => setEmail(event.target.value)} />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='' className='col-sm-3 col-form-label'>phone</label>
              <div className='col-sm-9'>
                <Input required placeholder='901-244-4324' type='text' value={phone} onChange={(event) => setPhone(event.target.value)} />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='' className='col-sm-3 col-form-label'>address</label>
              <div className='col-sm-9'>
                <Input required placeholder='123 Elf Road, North Pole, 88888' type='text' value={address} onChange={(event) => setAddress(event.target.value)} />
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='' className='col-sm-3 col-form-label'>how did you hear about us?</label>
              <div className='col-sm-9'>
                <Select
                  required
                  value={source}
                  onChange={(event) => setSource(event.target.value)}
                >
                  <option value=''>select option</option>
                  {source_options.map((option) => <option key={option} value={option}>{option}</option>)}
                </Select>
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='' className='col-sm-3 col-form-label'>what are you looking for?</label>
              <div className='col-sm-9'>
                <Textarea placeholder='gutter line with pure white lights and two columns' className='form-control' value={more_info} onChange={(event) => setMoreInfo(event.target.value)}></Textarea>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className='form-group row'>
              <label htmlFor='' className='col-sm-3 col-form-label'></label>
              <div className='col-sm-9'>
                <span>Hey {props.user.displayName}!</span>
              </div>
            </div>
            <div className='form-group row'>
              <label htmlFor='' className='col-sm-3 col-form-label'>what can we help you with?</label>
              <div className='col-sm-9'>
                <Textarea placeholder='bulb went out' className='form-control' value={more_info} onChange={(event) => setMoreInfo(event.target.value)}></Textarea>
              </div>
            </div>
          </Fragment>
        )
      }


      {
        error_message.length > 0 ? (
          <div className='form-group row'>
            <label htmlFor='' className='col-sm-3 col-form-label'></label>
            <div className='col-sm-9'>
              <span style={{ color: colors.RED, display: 'inline-block' }}>{error_message}</span>
            </div>
          </div>
        ) : null
      }
      <div className='form-group row'>
        <label htmlFor='' className='col-sm-3 col-form-label'></label>
        <div className='col-sm-9'>
          {
            submitted ? (
              <span style={{ color: colors.GREEN, display: 'inline-block', padding: '10px' }}>
                we've got it! we'll follow up with you soon
              </span>
            ) : (
              <FormButton disabled={submitting} type='submit'>{submitting ? 'sending to santa' : 'send to santa'}</FormButton>
            )
          }
        </div>
      </div>
    </form>
  )
}

function ContactForm () {

  return (
    <div
      style={{
        paddingTop: '40px',
        paddingBottom: '40px',
        backgroundColor: colors.WHITE,
      }}
      className='text-sm-left text-center'
    >
      <div className='container-xl' >
        <div className='row' style={{ paddingBottom: '20px' }}>
          <div className='col-sm'>
            <h2
              style={{
                color: colors.BLUE,
                fontSize: '40px',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}
              className='font-family-brand'
            >
              we'd love to hear from you!
            </h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm text-left'>
            <UserContext.Consumer>
              {({ user }) => {
                return (
                  <WebsiteForm
                    user={user}
                  />
                )
              }}
            </UserContext.Consumer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
