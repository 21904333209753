import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import colors from './colors'
import UserContext from './user-context'

import { firebase, db } from './firebase'

import LogoMain from './LogoMain'

const HeaderLink = (props) => {
  return (
    <div
      style={{
        fontSize: '20px',
        display: 'inline-block',
        paddingLeft: '20px',
      }}
      {...props}
    >
      <HashLink
        smooth
        style={{
          color: colors.WHITE,
        }}
        className='font-family-brand'
        {...props.linkprops}
      >
        {props.children}
      </HashLink>
    </div>
  )
}
const HeaderLinkInverted = (props) => {
  return (
    <div
      style={{
        fontSize: '20px',
        display: 'inline-block',
        paddingLeft: '20px',
      }}
      {...props}
    >
      <HashLink
        smooth
        style={{
          backgroundColor: colors.WHITE,
          padding: '10px',
          color: colors.BLUE,
          borderRadius: '4px',
        }}
        className='font-family-brand'
        {...props.linkprops}
      >
        {props.children}
      </HashLink>
    </div>
  )
}

function Header () {
  return (
    <div
      style={{
        backgroundColor: colors.BLUE,
        padding: 30,
        color: colors.WHITE,
      }}
      className='container-fluid'
    >
      <div className='row'>
        <div className='col-3'>
          <Link
            style={{
              height: '50px',
              width: '200px',
              textDecoration: 'none',
              display: 'inline-block',
            }}
            to='/'
          >
            <LogoMain />
          </Link>
        </div>
        <div className='col-9'>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <HeaderLink
              className='d-none d-md-flex'
              linkprops={{
                to: '/#HowItWorks',
              }}
            >
              how it works
            </HeaderLink>
            <HeaderLink
              className='d-none d-md-flex'
              linkprops={{
                to: '/#Faqs',
              }}
            >
              faqs
            </HeaderLink>
            <HeaderLink
              className='d-none d-md-flex'
              linkprops={{
                to: '/#GetInTouch',
              }}
            >
              get in touch
            </HeaderLink>
            <UserContext.HelperComponent
              NullComponent={() => (
                <span></span>
              )}
              UserComponent={(props) => (
                (
                  props.user
                  && /@lightelfs\.com$/.test(props.user.email)
                ) ? (
                  <HeaderLink
                    linkprops={{
                      to: '/quote-builder',
                    }}
                  >
                    quote builder
                  </HeaderLink>
                ) : null
              )}
            />
            <HeaderLinkInverted
              linkprops={{
                to: '/account',
              }}
            >
              <UserContext.HelperComponent
                NullComponent={() => (
                  <span>existing customers</span>
                )}
                UserComponent={(props) => (
                  <span>your account</span>
                )}
              />
            </HeaderLinkInverted>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
