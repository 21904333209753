import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/analytics"
import "firebase/storage"
import * as firebaseui from 'firebaseui'

import env from './env'

const app = firebase.initializeApp(env.FIREBASE_CONFIG)
const db = firebase.firestore()

firebase.analytics()

export {
  firebase,
  app,
  db,
  firebaseui,
}
