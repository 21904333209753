import React from 'react'

function LogoMain () {
  return (
    <div
      style={{
        backgroundImage: 'url(/svgs/main.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 0,
        backgroundSize: 'contain',
        height: '100%',
        color: 'transparent',
      }}
    >
    light elfs</div>
  )
}

export default LogoMain
